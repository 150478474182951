<template>
    <default-app-template>
        <div>
            <basic-headline>Demographics</basic-headline>
            <PatientDemographicsForm
                save-route="Dashboard"
                save-text="Save"
                back-route="Dashboard"
            />
        </div>
    </default-app-template>
</template>

<script>
export default {}
</script>
